import { useContext, useRef, useState } from "react";
import logo from "../assets/img/Kurs evi logo.svg";
import { MainContext } from "../utils/MainContext";
import { Auth } from "../utils/Auth";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

const Ticket = ({
  data,
  participationType,
  type,
  eventStartDate,
  eventLocation,
  eventPrice,
  eventHost,
  eventPhone,
  eventMail,
  eventName,
}) => {
  // Global State
  const { setTicketVisible } = useContext(MainContext);
  const { profilData } = useContext(Auth);

  // Local State
  const [isLoaded, setIsLoaded] = useState(false);
  const handleImageLoad = () => {
    setIsLoaded(true);
  };
  //   const handleDownloadPDF = () => {
  //     const input = document.querySelector(".ticket-body");

  //     html2canvas(input, {
  //       scale: 3, // Render kalitesini artırmak için ölçeği artırın
  //       useCORS: true, // QR kod gibi dış kaynaklı resimlerin yüklenmesini sağlar
  //     }).then((canvas) => {
  //       const imgData = canvas.toDataURL("image/png");
  //       const pdf = new jsPDF("p", "mm", "a4");
  //       const pdfWidth = pdf.internal.pageSize.getWidth();
  //       const pdfHeight = pdf.internal.pageSize.getHeight();

  //       // Scale to fit the PDF page
  //       const canvasWidth = canvas.width;
  //       const canvasHeight = canvas.height;
  //       const scaleFactor = Math.min(
  //         pdfWidth / canvasWidth,
  //         pdfHeight / canvasHeight
  //       );

  //       const imgWidth = canvasWidth * scaleFactor;
  //       const imgHeight = canvasHeight * scaleFactor;

  //       pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
  //       pdf.save("ticket.pdf");
  //     });
  //   };
  const content = useRef();
  const handleDownloadPDF = () => {
    const images = content.current.querySelectorAll("img");
    const imagePromises = Array.from(images).map((img) => {
      return new Promise((resolve) => {
        if (img.complete) {
          resolve();
        } else {
          img.onload = resolve;
          img.onerror = resolve; // Resolve on error to prevent blocking.
        }
      });
    });

    Promise.all(imagePromises).then(() => {
      html2canvas(content.current, { scale: 2, useCORS: true }).then(
        (canvas) => {
          const imgData = canvas.toDataURL("image/png");
          const pdf = new jsPDF("p", "pt", "a4");
          const pdfWidth = pdf.internal.pageSize.getWidth();
          const pdfHeight = pdf.internal.pageSize.getHeight();
          const imgWidth = pdfWidth - 40; // 20px right and left padding
          const imgHeight = (canvas.height * imgWidth) / canvas.width;

          pdf.addImage(imgData, "PNG", 20, 20, imgWidth, imgHeight);
          pdf.save("download.pdf");
        }
      );
    });
  };

  // Succes

  //   const handleDownloadPDF = () => {
  //   // Geçici bir div oluşturup, içeriği bu div'e ekle
  //   // const div = document.createElement("div");
  //   // div.innerHTML = data.description;
  //   // div.style.padding = "20px";
  //   // document.body.appendChild(div);

  //   html2canvas(content.current, { scale: 2 }).then((canvas) => {
  //     const imgData = canvas.toDataURL("image/png");
  //     const pdf = new jsPDF("p", "pt", "a4");
  //     const pdfWidth = pdf.internal.pageSize.getWidth();
  //     const pdfHeight = pdf.internal.pageSize.getHeight();
  //     const imgWidth = pdfWidth - 40; // 20px sağ ve sol boşluk
  //     const imgHeight = (canvas.height * imgWidth) / canvas.width;

  //     pdf.addImage(imgData, "PNG", 20, 20, imgWidth, imgHeight);
  //     pdf.save("download.pdf");
  //     // document.body.removeChild(div);
  //   });
  // };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { month: "long", day: "numeric", year: "numeric" };
    const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
      date
    );

    // Return the date in "June 10 - 2024" format
    return formattedDate.replace(",", " -");
  };

  return (
    <section
      className="ticket"
      onClick={() => setTicketVisible(false)}
      ref={content}
    >
      <div
        className="ticket-body"
        data-aos="fade-up"
        data-aos-duration="900"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="ticket-review-data">
          <div className="logo">
            <img src={logo} alt="kursevi" />
          </div>
          {eventName && <h2 className="event-name">{eventName}</h2>}

          <h2 className="personal-fullname">{profilData.fullname}</h2>

          {participationType && (
            <p className="general-info">
              <span className="label">İştirak Növü:</span>
              {participationType}
            </p>
          )}
          {eventPrice && (
            <p className="general-info pay-info-ticket">
              <span className="label">Ödəniş edilib:</span>
              <span className="price"> {eventPrice.price}</span>{" "}
              <span className="money-type"> AZN</span>
            </p>
          )}

          {eventHost && (
            <p className="general-info">
              <span className="label">Təşkilatçı:</span>
              {eventHost}
            </p>
          )}

          <p className="general-info">
            <span className="label">Mobil nömrə:</span>
            {profilData.phone}
          </p>
          {(eventMail || eventPhone) && (
            <p className="general-info support">
              <span className="label">Texniki dəstək:</span>
              {eventPhone} -{eventMail}
            </p>
          )}
        </div>

        <div className="qr-body">
          <div className="qr-photo">
            {!isLoaded && <div className="placeholder"></div>}
            <img
              src={`https://uimconsulting.com/uploads/${
                type === "courses" ? "courses" : "conferences"
              }/qr/${data.participant.signcode}.png`}
              alt="qr Code"
              onLoad={handleImageLoad}
              // style={isLoaded ? {} : { display: "none" }}
            />
          </div>
          <div className="event-main-info">
            {eventStartDate && (
              <p className="general-info ">
                <span className="label">Tarix:</span>
                {formatDate(eventStartDate)}
              </p>
            )}
            {eventStartDate && (
              <p className="general-info ">
                <span className="label">Saat:</span>
                {eventStartDate.split(" ")[1].slice(0, 5)}
              </p>
            )}

            {eventLocation && (
              <p className="general-info ">
                <span className="label">Yer:</span>
                {eventLocation}
              </p>
            )}
          </div>
        </div>
        <button className="no-print" onClick={() => window.print()}>
          Print/PDF
        </button>
        {/* <button className="no-pdf" onClick={handleDownloadPDF}>
          pdf
        </button> */}
      </div>
    </section>
  );
};

export default Ticket;
