import React, { useCallback, useEffect, useState } from "react";
import LandingPageSlider from "./sections/LandingPageSlider";
import InfoArea from "./sections/InfoArea";
import Innovation from "./sections/Innovation";
import { useLocation } from "react-router-dom";
import HomeBanner from "./sections/HomeBanner";
import axios from "axios";
import LastAddSlider from "./sections/LastAddSlider";
import { useTranslation } from "react-i18next";
import Loading from "../components/Loading";
import { infoAlert } from "../utils/SweetAlert";

const Home = () => {
  // Local State
  const [homeData, setHomeData] = useState(null);
  const [loading, setLoading] = useState(false);

  /* --------------------------------- Router --------------------------------- */
  const { pathname } = useLocation();
  // const location = useLocation();
  // console.log(location)

  //   useEffect(()=>{
  //     if(location.pathname==="/"){
  //       if(location.search.split("&")[0].slice(1)==="Status=success"){
  //         infoAlert("Uğurlu proses!","Balansın artırılması uğurla başa çatdı,zəhmət olmasa kurs/konfransa yenidən gedərək,qeydiyyatı tamamlayın")
  //         console.log(location.search.split("&")[0].slice(1))
  //       }
  //     }
  //   },[location.pathname, location.search])

  /* ---------------------- Reset Keeping Scrool Position --------------------- */
  // Status=success
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const { i18n } = useTranslation();

  const getHomeData = useCallback(async () => {
    setLoading(true);
    await axios
      .get(`https://api.uimconsulting.com/${i18n.language}/v3/sites/mainpage`, {
        crossdomain: true,
        rejectUnauthorized: false,
      })
      .then((res) => {
        setHomeData(res.data.payload);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [i18n.language]);
  useEffect(() => {
    getHomeData();

  }, [getHomeData]);


// 
// 
// 
// 
// 
// 
// 
// 
// 
// 
// 
// 
// 
// 
// 
// 
// 
// 
// 
// 
// 
//   
  const test = useCallback(async () => {
    setLoading(true);
    await axios
      .get(`https://api.uimconsulting.com/${i18n.language}/v3/dds/subcategorylist?category=5`, {
        crossdomain: true,
        rejectUnauthorized: false,
      })
      .then((res) => {
        setHomeData(res.data.payload);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [i18n.language]);
  useEffect(() => {
    test();
  }, [test]);

  return (
    <>
      {loading && <Loading />}
      <LandingPageSlider data={homeData && homeData[0]} />
      <InfoArea
        statistic={homeData && homeData[0].statistic}
        aboutus={homeData && homeData[0].aboutus}
      />
      <LastAddSlider data={homeData} />
      <Innovation />
      <HomeBanner />
    </>
  );
};

export default Home;
