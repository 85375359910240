import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const Card = ({ data, type, reload }) => {
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    if (reload) {
      setLoaded(false);
    }
  }, [reload]);
  return (
    <Link
      className="general-card"
      to={`/general-detail/${type === "cource" ? "courses" : "conferences"}/${
        data.id
      }`}
    >
      <div className="top">
        <h3 className="title">{data.subcategory}</h3>
        {/* <h4 className="subtitle">Onkologiya</h4> */}
      </div>
      <div className="middle">
        {!loaded && <div className="placeholder"></div>}
        <img
          src={`https://uimconsulting.com/uploads/${
            type === "cource" ? "courses" : "conferences"
          }/images/${data.image}`}
          alt={data.category}
          fetchpriority="high"
          onLoad={() => setLoaded(true)}
          style={{ display: loaded ? "block" : "none" }}
        />
      </div>
      <div className="bottom">
        <div className="info">
          <h2 className="title">{data.name}</h2>
          <span className="date">
            {data.startdate.split(" ")[0].split("-").reverse().join(".") +
              " - " +
              data.enddate.split(" ")[0].split("-").reverse().join(".")}
          </span>
        </div>
        <p className="company-name">{data?.partner?.name}</p>
      </div>
    </Link>
  );
};

export default Card;
