import { useTranslation } from "react-i18next";
import clock from "../../assets/img/icon/clock.svg";
import { Link, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";

const Program = ({ data, type }) => {
  // Local State
  const [handleCountDatas, setHandleCountDatas] = useState(4);
  const [uniqueCategories, setUniqueCategories] = useState([]);
  const [groupedDiaries, setGroupedDiaries] = useState([]);
  // Translation
  const { t } = useTranslation();

  // Location
  const location = useLocation();

  useEffect(() => {
    if (location) {
      setHandleCountDatas(4);
    }
  }, [location]);

  useEffect(() => {
    setUniqueCategories([]);

    if (location && data) {
      const uniqueDates = [];

      data.diary.forEach((diary) => {
        const date = diary.datetime.split(" ")[0];

        const existingDate = uniqueDates.find((item) => item.date === date);

        if (!existingDate) {
          uniqueDates.push({ date: date, count: 4 });
        }
      });

      // State'i güncelliyoruz
      setUniqueCategories(uniqueDates);
    }
  }, [data, location]);


  useEffect(() => {
    if (location && data) {
      // Geçici bir array ile gruplandırmayı yapacağız
      const diaryGroups = [];

      // Verileri döngüye alarak gruplandırma yapıyoruz
      data.diary.forEach((diary) => {
        const date = diary.datetime.split(" ")[0]; // Tarih kısmını alıyoruz

        // Gruplandırılmış dizide o tarihi buluyoruz
        const existingGroup = diaryGroups.find((group) => group.date === date);

        if (existingGroup) {
          // Eğer o tarih zaten varsa, o tarihe ait dizinin içerisine yeni diary ekliyoruz
          existingGroup.diaries.push(diary);
        } else {
          // Eğer o tarih daha önce eklenmediyse, yeni bir grup oluşturuyoruz
          diaryGroups.push({
            date: date,
            diaries: [diary],
          });
        }
      });

      // Gruplanmış verileri state'e atıyoruz
      setGroupedDiaries(diaryGroups);
    }
  }, [data, location]);

  return (
    <section className="detail-diarly ">
      {data.diary.length !== 0 && (
        <div className="container">
          <div className="caption">
            <h6 className="title">
              {t("detail_general_info.program_caption")}
            </h6>
            {data.programfile && (
              <Link
                to={`https://www.uimconsulting.com/uploads/${type}/files/${data.programfile}`}
                target="_blank"
                className="download"
              >
                PDF yüklə
              </Link>
            )}
          </div>
          {groupedDiaries.length !== 0 &&
            groupedDiaries.map((uniqueDate, index) => (
              <>
                <h3 className="date">{uniqueDate.date.split("-").reverse().join("-")}</h3>
                <div className="program">
                  {uniqueDate.diaries
                    .slice(0, uniqueCategories[index].count)
                    .map((program) => (
                      <div className="line" key={program.id}>
                        <div className="date">
                          <div className="icon">
                            <img src={clock} alt="clock" />
                          </div>
                          <div className="time">
                            {/* <span>{program.datetime.split(" ")[0]}</span>/ */}
                            <span>
                              {program.datetime.split(" ")[1].slice(0, 5)}
                            </span>{" "}
                          
                          </div>
                        </div>
                        <div
                          className="program-title"
                          dangerouslySetInnerHTML={{
                            __html: program.description,
                          }}
                        />
                      </div>
                    ))}
                </div>
                {Number(uniqueDate.diaries.length) > 4 && (
                  <div
                    className="btn-area"
                    onClick={() => {
                      setUniqueCategories((prevCategories) => {
                        // Kopyasını oluşturuyoruz
                        const updatedCategories = [...prevCategories];
      
                        // İlgili indeksteki count değerini güncelliyoruz
                        if (updatedCategories[index].count === 4) {
                          updatedCategories[index].count = data.diary.length;
                        } else {
                          updatedCategories[index].count = 4;
                        }
      
                        // Güncellenmiş diziyi geri döndürüyoruz
                        return updatedCategories;
                      });
                    }}
                    // onClick={() => {
                    //   if ( uniqueCategories[index].count=== 4) {
                    //     // setHandleCountDatas(Number(data.diary.length));
                    //   } else {
                    //     // setHandleCountDatas(4);
                    //   }
                    // }}
                  >
                    <button className="more">
                      {uniqueCategories[index].count === 4
                        ? t("general.read_more")
                        : t("general.hide")}{" "}
                    </button>
                  </div>
                )}
              </>
            ))}
        </div>
      )}
    </section>
  );
};

export default Program;
// import { useTranslation } from "react-i18next";
// import clock from "../../assets/img/icon/clock.svg";
// import { Link, useLocation } from "react-router-dom";
// import { useEffect, useState } from "react";
// // import jsPDF from "jspdf";
// // import html2canvas from "html2canvas";
// const Program = ({ data,type  }) => {
//   // const handleDownloadPdf = () => {
//   //   // Geçici bir div oluşturup, içeriği bu div'e ekle
//   //   const div = document.createElement("div");
//   //   div.innerHTML = data.description;
//   //   div.style.padding = "20px";
//   //   document.body.appendChild(div);

//   //   html2canvas(div, { scale: 2 }).then((canvas) => {
//   //     const imgData = canvas.toDataURL("image/png");
//   //     const pdf = new jsPDF("p", "pt", "a4");
//   //     const pdfWidth = pdf.internal.pageSize.getWidth();
//   //     const pdfHeight = pdf.internal.pageSize.getHeight();
//   //     const imgWidth = pdfWidth - 40; // 20px sağ ve sol boşluk
//   //     const imgHeight = (canvas.height * imgWidth) / canvas.width;

//   //     pdf.addImage(imgData, "PNG", 20, 20, imgWidth, imgHeight);
//   //     pdf.save("download.pdf");
//   //     document.body.removeChild(div);
//   //   });
//   // };
//   // Local State
//   const [handleCountDatas, setHandleCountDatas] = useState(4);
//   const { t } = useTranslation();

//   // Location
//   const location = useLocation();

//   useEffect(() => {
//     if (location) {
//       setHandleCountDatas(4);
//     }
//   }, [location]);

//   const handleDownload = async () => {
//     try {
//       // Dosyayı indir
//       const response = await fetch(
//         `https://www.uimconsulting.com/uploads/courses/files/${data.programfile}`
//       );

//       // Yanıtı Blob olarak al
//       const blob = await response.blob();

//       // Blob'u URL'e dönüştür
//       const url = window.URL.createObjectURL(blob);

//       // Bir <a> etiketi oluştur
//       const a = document.createElement("a");
//       a.href = url;
//       a.download = "document.pdf"; // İndirilecek dosyanın adı

//       // <a> etiketini tıklat
//       document.body.appendChild(a);
//       a.click();

//       // <a> etiketini temizle
//       document.body.removeChild(a);

//       // Kullanılmayan URL'yi temizle
//       window.URL.revokeObjectURL(url);
//     } catch (error) {
//       console.error("Dosya indirilemedi:", error);
//     }
//   };
//   return (
//     <section className="detail-diarly ">
//       {data.diary.length !== 0 && (
//         <div className="container">
//           <div className="caption">
//             <h6 className="title">
//               {t("detail_general_info.program_caption")}
//             </h6>
//             {data.programfile && (
//               <Link
//                 to={`https://www.uimconsulting.com/uploads/${type}/files/${data.programfile}`}
//                 target="_blank"
//                 className="download"
//               >
//                 PDF yüklə
//               </Link>
//             )}
//           </div>
//           <div className="program">
//             {data.diary.slice(0, handleCountDatas).map((program) => (
//               <div className="line" key={program.id}>
//                 <div className="date">
//                   <div className="icon">
//                     <img src={clock} alt="clock" />
//                   </div>
//                   <div className="time">
//                     {/* <span>{program.datetime.split(" ")[0]}</span>/ */}
//                     <span>{program.datetime.split(" ")[1].slice(0, 5)}</span>
//                   </div>
//                 </div>
//                 <div
//                   className="program-title"
//                   dangerouslySetInnerHTML={{
//                     __html: program.description,
//                   }}
//                 />
//               </div>
//             ))}
//           </div>
//           {Number(data.diary.length) > 4 && (
//             <div
//               className="btn-area"
//               onClick={() => {
//                 if (handleCountDatas === 4) {
//                   setHandleCountDatas(Number(data.diary.length));
//                 } else {
//                   setHandleCountDatas(4);
//                 }
//               }}
//             >
//               <button className="more">
//                 {handleCountDatas === 4
//                   ? t("general.read_more")
//                   : t("general.hide")}{" "}
//               </button>
//             </div>
//           )}
//         </div>
//       )}
//     </section>
//   );
// };

// export default Program;
